import React, { lazy, Suspense } from "react";
import ReactDOM from "react-dom/client";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
const HomePage = lazy(() => import("./WebPage/HomePage"));
const CertificatePage = lazy(() => import("./WebPage/CertificatePage"));
const Navbar = lazy(() => import("./WebPage/Navbar"));
const Footer = lazy(() => import("./WebPage/Footer"));
function App() {
  return (
    <>
      <Navbar />
      <BrowserRouter>
        <Suspense fallback={<h1>loading...</h1>}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/certificate/:name" element={<CertificatePage />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
      <Footer />
    </>
  );
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);
